import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const Select = loadable(() => import('/src/components/form-components/Select'))

const SelectTitle = ({ label, isSubmitting, isSubmitted, className }) => {
    const titles = ['Mrs', 'Mr', 'Miss', 'Ms', 'Mx', 'Dr', 'Other']

    return (
        <Select
            label={label}
            name="title"
            firstItem="Please select"
            data={titles}
            isSubmitting={isSubmitting}
            isSubmitted={isSubmitted}
            className={`c-select-field--title${className && ` ${className}`}`}
        />
    )
}

SelectTitle.propTypes = {
    /**
     * Field label
     */
    label: PropTypes.string.isRequired,
    /**
     * Whether form is submitting
     */
    isSubmitting: PropTypes.bool.isRequired,
    /**
     * Whether form is submitted
     */
    isSubmitted: PropTypes.bool.isRequired,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

SelectTitle.defaultProps = {
    label: 'First name',
    isSubmitting: false,
    isSubmitted: false,
    className: ''
}

export default SelectTitle
